<template>

  <div class="next">
    <div class="van-nav-bar van-hairline--bottom">
      <div class="van-nav-bar__content">
        <div class="van-nav-bar__left van-haptics-feedback"><i @click="back()"
                                                               class="van-badge__wrapper van-icon van-icon-arrow-left van-nav-bar__arrow"></i>
        </div>
        <div class="van-nav-bar__title van-ellipsis"></div>
      </div>
    </div>
    <div class="co">
      <div class="van-image" style="width: 120px; height: 120px;"><img
          :src="imgs_url" class="van-image__img" style="object-fit: cover;"></div>
      <div class="info"><p>{{ xuanfeidata.xuanfei_name }} <span v-if="false"
                                                                style="margin-left: 10px; color: rgb(250, 154, 10); font-size: 13px; font-weight: 600;">#KR51102</span>
      </p>
        <div class="rz"><img alt="" src="/img/beauty/rz.png"></div>
        <div class="jk"><p>{{ $t("text.bond") }}</p></div>
      </div>
    </div>
    <h1 class="tpzs">{{ $t("text.img") }}</h1>
    <div class="imgswiper">
      <div style=" margin-right: 10px;" v-for="(item,keys) in xuanfeidata.img_url" :key="keys">
        <div :class="keys==atr?'active imgI van-image':'imgI van-image'" style="width: 150px; height: 150px;"
             @click="imgskk(item.img,keys)">
          <img :src="item.img" class="van-image__img" style="object-fit: cover;border-radius: 10px">
        </div>
      </div>
    </div>
    <div class="sm">
      <div class="lef">
        <h1 class="title" style="padding: 10px 0px;">{{ $t("profile.jianjie") }}</h1>
        <div class="tips">
          <span v-for="(item,keys) in xuanfeidata.flag" :key="keys">{{ item }}</span>
        </div>
        <p>{{ $t("profile.height") }}: {{ xuanfeidata.height }} {{ $t("profile.cup") }}：{{ xuanfeidata.cup }}</p>
        <p class="sf">{{ $t("text.intention") }}：<span v-for="item in xuanfeidata.number" :key="item"></span></p>
        <p>{{ $t("profile.item") }}：{{ xuanfeidata.area }}</p></div>
    </div>
    <div class="foot" v-if="false">
      <h1 style="padding-bottom: 0px;">{{ $t("profile.video") }}</h1>
      <div style="padding-top: 10px;">
        <video width="98%" fit="contain" height="100%" :src="xuanfeidata.pvideo" controls="true"
               controlslist="nodownload"/>
      </div>
    </div>
    <div class="jianjie"><h2>{{ $t("text.casual") }}</h2>
      <p>{{ $t("text.txt1") }}</p>
    </div>
    <div class="foots"><a  @click="dialog = !dialog">{{ $t("text.txt2") }}</a></div>
    <div class="van-overlay" style="z-index: 2007; " v-if="dialog"></div>
    <div class="van-dialog van-popup van-popup--center" style="z-index: 2008; "  v-if="dialog">
      <div class="van-dialog__header">{{$t("text.txt3")}}</div>
      <div class="van-dialog__content"><p style="padding: 20px 20px 0px; font-size: 16px; color: rgb(125, 118, 239);">
        {{$t("text.txt4")}}</p></div>
      <div class="van-hairline--top van-dialog__footer">
        <div class="van-button van-button--default van-button--large van-dialog__cancel" @click="dialog = !dialog">
          <div class="van-button__content"><span class="van-button__text">{{$t("text.cancel")}}</span></div>
        </div>
        <div class="van-button van-button--default van-button--large van-dialog__confirm van-hairline--left"  @click="toService">
          <div class="van-button__content"><span class="van-button__text" > {{$t("text.ok")}}</span></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import videojs from "video.js";

export default {
  data() {
    return {
      dialog: false,
      vod_name: "",
      imgs_url: "",
      xuanfeidata: [],
      atr: 0,
      active: 0,
      addlist: [
        'profile.portrait',
        'profile.video'
      ],
      nowPlayVideoUrl: ""
    };
  },
  methods: {
    nunfuncti() {
      ++this.xuanfeidata.dianzan;
    },
    toService(){
      this.dialog = !this.dialog;
      if(this.$store.getters.getBaseInfo.iskefu == 1){
        this.$router.push("ServiceOnline");
      }else {
        this.$toast(this.$t("msg.功能已禁用!"));
      }
    },
    imgskk(img, key) {
      this.imgs_url = img;
      this.atr = key;
    },
    lanfun(type) {
      this.actives = type
      console.log(this.actives);
    },
    back() {
      return window.history.back();
    },
    getxuanfeidata() {
      this.$http({
        method: 'get',
        url: 'xuanfeidata',
        data: {id: this.$route.query.id}
      }).then(res => {
        this.xuanfeidata = res.data;
        this.imgs_url = res.data.img_url[0].img;

        this.nowPlayVideoUrl = this.xuanfeidata.pvideo;
        this.isMp4 = 1;
        this.cover = "";

        this.getVideo();

      });
    },

    getVideo() {
      this.player = videojs("my-video", {
        height: "200px",
        width: "100%",
        preload: "auto", // 预加载
        controls: false,  // 显示播放的控件
        multipleArray: [0.75, 1, 1.5, 2], // 倍速设置
      });
      this.type = "application/x-mpegURL";
      if (this.isMp4 == 1) {
        this.type = "";
      }
      this.player.src([{
        src: this.nowPlayVideoUrl,
        type: this.type // 告诉videojs,这是一个hls流
      }]);

    },
    yuyue() {
      this.$toast(this.$t("msg.请联系顾问或接待员"));
    }
  },
  created() {
    this.vod_name = this.$route.query.name;
    this.getxuanfeidata();
  }
};
</script>

<style>
.imgswiper {
  width: 100%;
  overflow-x: scroll;
  margin: 4vw 0;
  display: flex;
  flex-wrap: nowrap;
}

</style>
