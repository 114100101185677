const Cht = {
    text: {
        txt37: "投票記錄",
        choose: "匹配",
        bond: "已認證並支付10000美元押金",
        img: "肖像表現",
        intention: "脚部耐受性",
        casual: "隨意的性指導",
        txt1: "注意：該平臺提供最正宗的空運掛鉤。 為了保護用戶隱私，會員需要聯系客戶服務或推薦的成功人士。",
        txt2: "客戶服務顧問",
        txt3: "問詢處",
        txt4: "請記下你姐姐的電話號碼並聯系客服",
        ok: "好的",
        cancel: "取消",
        txt5: "對於成員來說，網站只顯示了1%的資源，更新是不規則的。 有關更多詳細資源，請聯繫我們的客戶服務。",
        txt6: "平臺上的所有會員資訊均真實、安全、可靠。 你可以在同一個都市找到新的邂逅。 選擇你最喜歡的女人，讓我們見面。 讓我們現在加入",
        txt7: "會員資訊",
        txt8: "匹配",
        txt9: "利用協定",
        txt10: "我想和她約會",
        txt11: "我們的平臺為您提供最逼真的寧靜外觀。 為了保護個人資訊的隱私，只有聯系支持人員或收到已經經歷過尋找成功扣押經驗的人的實名推薦，才能注册。",
        txt12: "0個線上配對",
        txt13: "44已啟動",
        txt14: "會員福利",
        txt15: "活動",
        txt16: "會員視頻",
        txt17: "欺騙他",
        txt18: "會員資訊",
        txt19: "新ID",
        txt20: "邂逅",
        txt21: "投票結束",
        txt22: "期間結果",
        txt23: "期間",
        txt24: "隨機選擇數位",
        credit_score: "信用評分",
        mail: "車站信函",
        txt25: "陞級需要15分",
        txt26: "個人積分",
        txt27: "積分",
        txt28: "積分收費",
        txt29: "交換點數",
        txt30: "線上服務",
        txt31: "簡況",
        txt32: "資金明細",
        txt33: "提現明細",
        txt34: "數據記錄",
        txt35: "提現密碼",
        txt36: "交換點數",

    },
    login: {
        title: "登入",
        username: "請輸入用戶名",
        password: "請輸入您的密碼",
        forgetPaw: "忘記密碼",
        registerNow: "沒有帳號？ 立即注册",
        doLogin: "登入",

    },
    profile: {
        jieguo: "投票",
        jiesu: "結束",
        portrait: "描繪一個人",
        video: "視頻",
        jianjie: "個人資料",
        liji: "現在預約",
        chongdan: "充電範圍",
        fd: "女士",
        yuyue: "預訂",
        archives: "女孩檔案",
        age: "年齡",
        height: "身高",
        weight: "重量",
        cup: "杯子尺寸",
        city: "都市",
        area: "服務區域",
        item: "服務專案"
    },
    register:{
        title: "登記處",
        username: "請輸入用戶名（6-12個英文字母或數位）",
        password: "請輸入您的登入密碼（6-12個英文字母或數位）",
        code: "請輸入邀請碼",
        agreement: "我已經知道並同意開戶協定和條約",
        chooseAgreement: "請查看下麵的開戶協定！",
        doRegister: "注册",

    },
    footer:{
        home : "主頁",
        game: "預訂",
        video: "視頻",
        mine: "礦",

    },
    game:{
        title: "預約大廳",
        all: "全部",

    },
    choose:{
        title: "選擇一個妾",
        city: "都市百科全書",
        rule: "該平臺是全網最真實+商務護航+同城激情。 為確保每位用戶的個人隱私，客戶只能通過聯系前臺或平臺高級成員的實名推薦加入",
        price: "價格流",
        resource: "有哪些資源？",
        character: "網上名人糢特空姐溫柔糢特大學生只有你想不到沒有本平",
        scope: "服務範圍？",
        location: "在同一個都市免費槍支。 在中國的一線和二線都市，任何地方都是空中的。 當地有三線都市。 您也可以聯系前臺預約",

    },
    home:{
        recommend: "推薦任務",
        more: "查看更多",
        popularity: "人氣排名",
        hot: "熱門推薦",

    },
    lottery:{
        record: "任務記錄",
        rule: "遊戲規則",
        prompt: "遊戲提示",
        gameplay: "從可選的求和錶中選擇一個數位進行投注",
        explain: "獲獎描述",
        wprole: "三個彩票號碼的總價值為11~18； 3~10的總和值較小；",
        example: "投注示例",
        option: "投注計畫：小彩票號碼：123，即中小型",
        task: "任務清單",
        money: "可用餘額",
        submit: "提交",
        selection: "當前選擇",
        perNote: "每張紙幣金額",
        inputMoney: "請輸入金額",
        total: "共計",
        note: "共計",
        statistics: "共計",
        empty: "清空訂單",
        verify: "提交確認",
        issue: "發行編號",
        num: "彩票號碼",
        big: "大",
        small: "小的",
        single: "單一的",
        double: "雙人間",
        unselected: "未選擇",
        drawLottery: "抽獎成功期編號：",
        kefu: "請聯系管理員完成此任務！",

    },
    mine:{
        recharge: "充值",
        withdraw: "撤回",
        myMoney: "我的錢包",
        detail: "細節",
        balance: "平衡",
        finance: "個人報告",
        order: "帳戶詳細資訊",
        record: "任務記錄",
        my: "個人中心",
        notice: "資訊公告",
        service: "線上客服",
        score: "完整的",

    },
    setting:{
        title: "設定",
        basic: "基本資訊設定",
        password: "登入密碼",
        capital: "資金密碼",
        lang: "語言",
        quit: "登出",

    },
    setPayPassword: {
        title: "設定資金密碼",
        opw: "請輸入資金密碼",
        tpw: "請重新輸入資金密碼",

    },
    gameRecord:{
        reject: "拒絕",
        profit: "利潤",
        place: "下訂單",
        title: "任務記錄",
        amountBet: "投注金額",
        create: "訂購時間",
        update: "結算時間",

    },
    video:{
        title: "視頻影院",
        play: "播放",

    },
    playVideo:{
        play: "次要遊戲",

    },
    setname:{
        title: "修改您的真實姓名",
        name: "姓名",
        truename: "請輸入您的真實姓名",
        hint: "為了您帳戶的安全，實名需要與綁定的銀行卡名稱一致",

    },
    setsex:{
        title: "性別修改",

    },
    bindCard:{
        title: "填寫收款卡",
        msg: "請輸入您的收款卡資訊",
        hint: "尊敬的用戶，為了確保您的資金安全，請綁定您的真實姓名並設定提款密碼。 如果名稱與開戶名稱不一致，您將無法取款",
        card: "帳號（正常）",
        truecard: "輸入帳號（正常）",
        name: "戶名（實名）",
        truename: "輸入帳戶名（真實姓名）",
        financial_institution: "金融機構名稱",
        truefinancial_institution: "輸入金融機構的名稱",
        branch: "分行名稱",
        truebranch: "輸入分支機搆名稱",
        branch_number: "分行編號",
        truebranch_number: "輸入分行編號"
    },
    setbank:{
        title: "收款卡資訊",
        bank: "添加銀行卡",
        hint: "提示：請綁定到大型商業銀行。 如果您需要修改，請聯系線上客服",

    },
    setLoginPassword:{
        title: "修改登入密碼",
        old_password: "舊密碼",
        old_password_p: "請輸入您的舊密碼",
        o_new_password: "新密碼",
        o_new_password_p: "請輸入新密碼",
        t_new_password_p: "請重新輸入新密碼",

    },
    infomation:{
        title: "基本資訊",
        head: "頭像",
        select: "選擇頭像",
        name: "真實姓名",
        sex: "性別",
        man: "男性",
        girl: "女性的",
        unknown: "未知",
        binding: "綁定資訊",

    },
    withdraw: {
        title: "現金選取中心",
        record: "提款記錄",
        money: "現金提款金額",
        all: "全部",
        min: "單一限制：最小",
        max: "",
        num: "提現：一天內提現",
        hint: "到達時間：一般來說，到達時間約為5分鐘，最快到達時間為2分鐘",
        quota: "配額說明",
        balance: "平衡",
        withdraw: "立即選取現金",

    },
    withdrawRecord:{
        desc: "說明",
        create: "提交時間",
        update: "稽核時間",

    },
    personalreport:{
        title: "個人報告",
        profit: "利潤金額",
        formula: "利潤計算公式：中獎金額任務金額",
        task: "任務量",
        recharge: "充值金額",
        withdrawal: "現金提款金額",
        win_money: "中獎金額",

    },
    service: {
        title: "線上客服",
        relation: "接觸",
        hint: "7*24小時竭誠為您服務",

    },
    msg:{
        "下单": "下訂單",
        "次": "時間",
        "用户不存在！": "用戶不存在！",
        "密码错误！": "密碼錯誤！",
        "登录成功！": "登入成功！",
        "邀请码不存在！": "邀請碼不存在！",
        "用户名位数错误！": "用戶名編號錯誤！",
        "密码位数错误！": "密碼數量錯誤！",
        "用户名不能存在中文！": "中文用戶名不能存在！",
        "邀请码不能为空！": "邀請碼不能為空！",
        "注册成功！": "注册成功！",
        "注册失败！": "注册失敗！",
        "用户已存在": "用戶已存在",
        "刷新成功！": "重繪成功！",
        "请联系顾问或接待员": "請聯系您的顧問或接待員",
        "金额错误！": "金額錯誤！",
        "请选号！": "請選擇號碼！",
        "请填写金额!": "請填寫金額！",
        "余额不足，请联系客服充值！": "如果餘額不足，請聯系客服充值！",
        "没有更多了": "沒有了",
        "没有数据": "無數據",
        "余额不足！": "餘額不足！",
        "扣款失败！": "扣款失敗！",
        "下注异常！": "投注异常！",
        "投注成功！": "投注成功！",
        "参数异常！": "參數异常！",
        "获取中": "得到…",
        "请完成任务单后进入": "請完成任務清單",
        "请联系客服充值": "請聯系客服充值",
        "请设置收款卡!": "請設定收款卡！",
        "功能已禁用!": "功能已禁用！",
        "账号下线": "帳戶離線",
        "登录/注册": "登入/注册",
        "登录可享受更多服务！": "登入享受更多服務！",
        "未设置": "未設定",
        "已设置": "已設定",
        "提交": "提交",
        "确定": "確定",
        "请填写完整": "請填寫",
        "两次密码不一致！": "密碼兩次不一致！",
        "设置提现密码成功！": "設定提款密碼成功！",
        "提现密码已设置，需要修改请联系客服": "提款密碼已設定，需要修改。 請聯系客服",
        "已绑定": "綁定",
        "无": "沒有人",
        "更换头像成功！": "T替換頭像成功！",
        "更新头像失败！": "更新頭像失敗！",
        "请勿重复设置！": "不要重複設定！",
        "设置姓名成功！": "設定名稱成功！",
        "设置姓名失败！": "設定名稱失敗！",
        "设置性别成功！": "設定性別成功！",
        "设置性别失败！": "設定性別失敗！",
        "请设置姓名后再绑定银行卡！": "請在綁定銀行卡之前設定您的姓名！",
        "请设置提现密码后再绑定银行卡！": "請在綁定銀行卡之前設定提款密碼！",
        "确认绑卡": "確認卡綁定",
        "请输入银行卡号！": "請進入銀行！",
        "请输入银行名称！": "請輸入銀行名稱！",
        "设置银行卡成功！": "銀行卡設定成功！",
        "设置银行卡失败！": "設定銀行卡失敗！",
        "旧密码错误": "舊密碼錯誤",
        "修改密码成功！": "密碼更改成功！",
        "修改密码失败！": "密碼更改失敗！",
        "请填写正确的金额": "請填寫正確的金額",
        "提现金额不能少于：": "現金提款金額不能小於：",
        "提现金额不能大于：": "現金提款金額不能大於：",
        "当日提现次数已用完": "當天的現金提款已用完",
        "提现成功！": "提款成功！",
        "提现失败！": "提款失敗！",
        "金额": "數量",
        "待审核": "待審查",
        "审核成功": "稽核成功",
        "审核退回": "稽核報告",
        "请充值后观看视频！": "請充值並觀看視頻！",
        "释放即可刷新": "釋放以重繪。。。",
        "加载中": "加載。。。",
        "下拉即可刷新": "下拉以重繪。。。",

    },
    setLang:{
        title: "設定語言"
    },
    lang:{
        zh: "Chinese ",
        jp: "Japan ",
        ara: "English ",
        en: "English ",
        spa: "Spanish ",
        it: "Italian ",
        de: "German ",
        pt: "Portuguese ",
        fra: "French ",
        nl: "Dutch ",
        id: "Indonesian ",
        hi: "Hindi ",
        kor: "Korean ",
        tr: "Turkish ",
        swe: "Swedish ",
        ga: "Irish ",
        ru: "Russian ",
        vie: "Vietnamese ",
        th: "Thai ",
        est: "Estonian ",
        be: "Belarusian ",
        bul: "Bulgarian ",
        pl: "Polish ",
        ms: "Malay ",
    }
}
export default Cht