import Vue from 'vue';
import App from './App.vue';
import Vant from 'vant';
import 'vant/lib/index.css';
import router from './router';
import http from './http';
import VueAwesomeSwiper from 'vue-awesome-swiper'
import common from './common/function'
import 'swiper/swiper-bundle.css'
import store from './store'


import 'video.js/dist/video-js.css'
import en from "./locale/lang/en";
import zh from "./locale/lang/zh";
import vie from "./locale/lang/vie";
import jp from "./locale/lang/jp";
import ara from "./locale/lang/ara";
import spa from "./locale/lang/spa";
import it from "./locale/lang/it";
import de from "./locale/lang/de";
import pt from "./locale/lang/pt";
import fra from "./locale/lang/fra";
import nl from "./locale/lang/nl";
import id from "./locale/lang/id";
import hi from "./locale/lang/hi";
import kor from "./locale/lang/kor";
import tr from "./locale/lang/tr";
import swe from "./locale/lang/swe";
import ga from "./locale/lang/ga";
import ru from "./locale/lang/ru";
import th from "./locale/lang/th";
import ms from "./locale/lang/ms";
import pl from "./locale/lang/pl";
import bul from "./locale/lang/bul";
import be from "./locale/lang/be";
import est from "./locale/lang/est";
import cht from "./locale/lang/cht";


var langArr = {en,zh,vie,jp,ara,spa,it,de,pt,fra,nl,id,hi,kor,tr,swe,ga,ru,th,ms,pl,bul,be,est,cht};

var lang = localStorage.getItem('lang');

var langStr = ["en","zh","vie","jp","ara","spa","it","de","pt","fra","nl","id","hi","kor","tr","swe","ga","ru","th","ms","pl","bul","be","est","cht"];

/*if(lang == "" || langStr.indexOf(lang) == -1) {
  var langStr1 = ["en","zh","vi","jp","ar","sp","it","de","pt","fr","nl","id","hi","kor","tr","sw","ga","ru","th","ms","pl","bu","be","es"];
  var JsSrc = (navigator.language || navigator.browserLanguage).toLowerCase();
  langStr1.map(function (item, key) {
    if (JsSrc.indexOf(item) >= 0) {
      lang = langStr[key]
      localStorage.setItem('lang', lang);
      // 假如浏览器语言是中文
    }
  })
}*/
Vue.prototype.$t = function (a) {
  lang = localStorage.getItem('lang');
  if(lang == "" || lang == null || langStr.indexOf(lang) == -1){
    this.$http({
      method: 'get',
      url: 'lang_getdefault'
    }).then(res=>{
      localStorage.setItem('lang',res.data.lang);
      window.location.reload();
    })
  }
  var s = a;
  var name = langArr[lang];

  var lets = s.split('.').length;
  var ti = s.split('.');
  for(var i = 0;i<lets;i++){
    name = name[ti[i]];
  }
  if(!name){
    name = ti[1];
  }
  return name;
}

Vue.prototype.$http = http
Vue.prototype.common = common
Vue.config.productionTip = false
Vue.use(VueAwesomeSwiper, /* { default options with global component } */)
Vue.use(Vant);

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
